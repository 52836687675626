import React, { useState, useEffect } from 'react';
import Wish3 from './Wish3';
import WishForm from './WishForm';
import Errors from './Errors';
import Appalert from './Appalert';
import { Modal } from 'shards-react';
import { Anchor, Box, Button, Grid, Page, PageContent, PageHeader, Spinner } from 'grommet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import userservice from './services/userservice';
import { useContext } from 'react';
import { UserContext } from './services/user-context';

const Whishlist = (props) => {

  const [wishes, setWishes] = useState([]);
  const [username, setUsername] = useState("");
  const [alert, setAlert] = useState("");

  const [wishlist, setWishlist] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [title, setTitle] = useState("");
  const updateAlert = (message, status)=>{
    console.log("setting alert", message)
      setAlert(previousState => {
      return { ...previousState, message: message, status:status }
    });
  }

  const self = props.user === "me";

  useEffect(() => {
    const user = props.user;
    fetch('/api/users/' + user + '/wishlists')
      .then(res => {
        if (res.ok) {
          return res.json();
        } else if (res.status == 401) {
          props.updateUser("")
          userservice.signout()
          throw res
        }
      })
      .then(responseJson => {
        setWishes(responseJson[0].Wishes);
        setWishlist(responseJson[0]);
        setUsername(responseJson[0].User.name);
        setTitle(self ? "Ma liste de cadeaux" : "Liste de " + responseJson[0].User.name);
        setIsLoading(false);
      })
      .catch(error => {
        console.log("error ", error)
      }
      );
  }, [props.user, self]);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const getWishDetails = async (url) => {
    const q = "/api/wishes/findURL";
    try {
      const response = await fetch(q, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const postwish = (newwish) => {
    const q = '/api/wishlists/' + wishlist.uid + '/wishes';
    fetch(q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newwish),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        updateAlert("Cadeau ajouté", "normal");
        setWishes([...wishes, data]);
      })
      .catch(err => updateAlert(err.message, "normal"));
  };

  const putwish = (newwish, wishId) => {
    fetch('/api/wishes/' + wishId, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newwish),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        updateAlert("Cadeau modifié", "normal");
        setWishes(wishes.map(wish => wish.uid === wishId ? data : wish));
      })
      .catch(err => updateAlert(err.message, "critical"));
  };

  const deletewish = (wishId) => {
    setIsLoading(true);
    fetch('/api/wishes/' + wishId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() => {
        updateAlert("Cadeau supprimé", "normal");
        setWishes(wishes.filter(wish => wish.uid !== wishId));
        setIsLoading(false);
      })
      .catch(err => updateAlert(err.message, "critical"));
  };

  const StyledAnchor = styled(Anchor)`
    text-decoration: none !important;
    &:hover {
      text-decoration: none;
      color: #C7614D;
    }
  `;

  const StyledLink = styled(Link)`
    text-decoration: none !important;
    &:hover {
      text-decoration: none;
      color: #C7614D;
    }
  `;

  const wishArray = wishes
    .sort((item1, item2) => item2.importance - item1.importance || item1.name.localeCompare(item2.name))
    .map(item => (
      <Wish3
        getWishDetails={getWishDetails}
        putwish={putwish}
        deletewish={deletewish}
        key={item.uid}
        name={item.name}
        importance={item.importance}
        isSecret={item.isSecret}
        isPurchased={item.isPurchased}
        self={self}
        comment={item.comment}
        img={item.img}
        createdAt={item.createdAt}
        url={item.url}
        id={item.uid}
      />
    ));

  return (
    <Page>
      <PageHeader
        actions={
          <Button
            primary
            disabled={!collapse}
            onClick={toggle}
            label={`${self === false ? '👻' : ''} Ajouter un cadeau ${self === false ? 'secret' : ''}`}
          />
        }
        parent={<StyledLink to="/app/families/"><StyledAnchor label="Familles" preventScrollReset={true} /></StyledLink>}
        title={title}
      />

      <Modal backdropClassName="modalBackdrop" open={!collapse}>
        <WishForm getWishDetails={getWishDetails} empty={false} toggle={toggle} handleClick={postwish} self={self} />
      </Modal>

      <PageContent>
        {isLoading && (
          <div>
            <Spinner />
            <span><br />Chargement des cadeaux...</span>
          </div>
        )}
        {alert.message&&
            <Appalert setAlert={updateAlert} alert={alert}></Appalert>
        }
        <Box>
          <Grid fill="horizontal" gap="small" columns={{ size: "medium" }}>
            {wishArray}
          </Grid>
          {wishArray.length === 0 && !isLoading && <Errors />}
        </Box>
      </PageContent>
    </Page>
  );
};

export default Whishlist;
